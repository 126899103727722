// NO JQUERY

// TODO help - best way to sort ??
var outletList = (function(){
	var sortMap = 
	[
    		{ v: "string:-RelevenceScore", q: "fs=Relevance&csd=DESC", meta:"" },
        	{ v: "string:SellPrice1", q: "fs=LowestSellPrice&csd=ASC", meta: "Price Low-High" },
        	{ v: "string:-SellPrice1", q: "fs=LowestSellPrice&csd=DESC", meta: "Price High-Low" },
        	{ v: "string:MinOrderQty", q: "fs=MinOrderQty&csd=ASC", meta: "Minimum Quantity"},
        	{ v: "string:ProductName", q: "fs=ProductName&csd=ASC", meta: "Product Name" }
	],
	productContainer = document.getElementById('productContainer'),
	defaultPageSize = 50,
	path = window.location.pathname,
	pageSize = 0,
	pageNumber = 1,
	init = function(){
		filterEvents().InitializeState();
		if(document.getElementById('pageSort')){
			document.getElementById('pageSort').addEventListener('change', function(e) {
				filterEvents().UpdateFilterUrl(this.value);
			});
		}
		if(document.querySelector(".bottomPaging") && document.querySelector(".bottomPaging").querySelector("nav")){
			document.querySelector(".bottomPaging").querySelector("nav").setAttribute("aria-label", "Product Paging Bottom");
		}
		document.getElementById('itemsPerPageDropDown').addEventListener('change', function(e) {
			filterEvents().AddPageSize(this.value);
			filterEvents().UpdatePathName();
			filterEvents().UpdateProducts(true);					
		});
	}, 
	filterEvents = function(){
		var setPageSize = function(id) {
			document.getElementById('itemsPerPageDropDown').value = id;
		},
		addPageSize = function(size) {
			pageSize = size;
		},
		addPageNumber = function(page) {
			pageNumber = page;
		},
		updatePathName= function() {
			var params = new URLSearchParams(window.location.search.slice(1));
			params.set('ps', pageSize);
			path = window.location.pathname + '?' + params.toString();
		},		
		updatePageSize = function(pageSize) {
			//const currentUrl = window.location.href;
			//replaceCurrentUrl(pageSize);
		},	
		updateProducts = function(updateUrl) {
			ajaxActions().GetProducts(path, updateUrl, '');
		},		
		replaceCurrentUrl = function (newUrl, newTitle, newDescription, newKeywords) {
        		var old = window.location.href;
        		var oldTitle = document.title;
        		var oldKeywords = document.querySelectorAll('meta[name="keywords"]')[0].getAttribute('content');
        		var oldDescription= document.querySelectorAll('meta[name="description"]')[0].getAttribute('content'); 
        		var urlArray = old.split('/');
        		var prot = urlArray[0]; 
        		var host = urlArray[2];
        		var url = prot + '//' + host;
        		if ((newUrl.indexOf('?') == 0 &&  old.indexOf('?') > -1 && old.slice(old.indexOf('?')) != newUrl) || old.replace(url, '') != newUrl) {
            			history.pushState({title: oldTitle, keywords: oldKeywords, description: oldDescription}, '', old);
            			history.replaceState({title: newTitle, keywords: newKeywords, description: newDescription}, '', newUrl);
            			document.title = newTitle;
			}
		},
		updateFilterUrl = function(filter){
			window.location.href = filter;
		},		
		initializeState = function() {
			var loc = window.location.href;
			var title = document.title;
			var keywords = document.querySelector('meta[name="keywords"]').getAttribute('content');
			var description = document.querySelector('meta[name="description"]').getAttribute('content');
			history.replaceState({ title: title, keywords: keywords, description: description }, '', loc);
		};
		
		return {
			SetPageSize: setPageSize,
			AddPageSize: addPageSize,
			AddPageNumber: addPageNumber,
			UpdatePageSize: updatePageSize,
			UpdatePathName: updatePathName,
			UpdateProducts: updateProducts,
			ReplaceCurrentUrl: replaceCurrentUrl,
			UpdateFilterUrl: updateFilterUrl,
			InitializeState: initializeState
		};			
	},
	ajaxActions = (function() {
		var antiForgeryToken = { 'RequestVerificationToken': document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value },
		verificationToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value,
		getProducts = function(path, updateUrl) {
			var loading = document.getElementById('loadingItems');

			loading.classList.remove('hide');
			productContainer.classList.add('transparent');
			document.getElementById('mainContent').scrollIntoView({ behavior: 'smooth' });
	        			
			fetch(path, { 
				method: 'POST', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.text() : []; 
			}).then(function(response){
				// Update with the new results.
				productContainer.innerHTML = response;

				loading.classList.add('hide');
				productContainer.classList.remove('transparent');

				if (updateUrl === true) {
					filterEvents().ReplaceCurrentUrl(path, document.getElementById('BaseTitle').value, document.getElementById('BaseDescription').value, document.getElementById('BaseKeywords').value);
				}
			}).catch(function(error){
				console.log("Failed to get products for: " + path);

				// try reloading page with new filter path
				window.location.href = window.location.host  + path;
			});
		};
		
		return {
			GetProducts: getProducts
		};		
	});
	
	return{
		Init: init
	};
})();

(function(){
	outletList.Init();
})();